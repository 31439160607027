import * as React from "react";
import PageComponent from "src/components/pages/the-process";

const Page = ({ location }) => {
  const copy = {
    list: [
      `The first step in buying Life Insurance is to decide on the approximate amount of insurance that you wish to buy. If you aren’t sure, the best way to ascertain this is to have a meeting over the phone or via video conference with a qualified insurance agent. Video conference is easy and can be accessed thru any online computer. We will assist in setting up the meeting.`,
      `The next step is to determine the underwriting category the insured will receive which determines the premium rate. For young healthy prospective insureds and/or small amounts of coverage, this may be estimated through a brief medical history interview. For many prospective insureds the best way to informally underwrite is by reviewing medical records. With your consent we can handle the entire process of ordering your medical file from your doctor(s). The relevant information is submitted to the insurance carrier underwriter for an informal medical underwriting assessment. If a person visits his/her doctor regularly the assessment tends to be 90%+ accurate.`,
      `Once the underwriting category and estimated price have been determined, another meeting is arranged to review the results and how they translate into various costs and options. If this review results in serious interest in the purchase of life insurance, formal underwriting must be pursued.`,
      `Formal underwriting consists of the completion and signing of an application and a medical exam. Formal underwriting may always be completed in the U.S. Depending on the insurance company, the exam may be possible in Israel, but the application may never be signed in Israel. At some point travel outside of Israel will be necessary to complete the sales process. The U.S. is always acceptable but depending on the carrier Switzerland, United Kingdom or Germany may be a viable option for the insured to sign the application. Once the underwriting venue is determined we will arrange for the exam and application signing. In the U.S. this is done at a time and place of your choice. The exam typically takes under 1 hour and is usually performed by a nurse. The completion and signing of the application also takes under an hour. Depending on the carrier it may be advisable to create a basic revocable trust domiciled in the USA to own the policy. This can be very helpful in simplifying the logistics of the underwriting process. If creating a trust makes sense, we can help you find a very economical way to accomplish this.`,
      `Once formal underwriting is completed and the application is submitted we wait for the carrier to give a formal offer of insurance which allows us to consider actual costs. At this time it is still possible to change the amount (subject to underwriting) and type of coverage to be issued.`,
      `Once policies are issued and initial premium payment is made the coverage is in full effect.`,
    ],
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="The Process"
    />
  );
};

export default Page;
